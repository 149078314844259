import React from 'react'

import './cards.css'

/**
 * Homepage component, displays information about the platform.
 */
const Contact = () => (
  <div className="cards">

    <div className="card">
      <h1>Contact met de Netbeheerders</h1>
      <p>
        Als u meer informatie nodig heeft neem dan contact op met één van onderstaande netbeheerders:
      </p>
      <p>
        <a href="https://www.liander.nl/contact" target="_blank" rel="noopener noreferrer"
          title="Contact met Liander">
          <img src={require('./alliander.jpg')} alt="Contact met Liander" />
        </a>
      </p><p>
        <a href="https://www.stedin.net/klantenservice" target="_blank" rel="noopener noreferrer"
          title="Contact met Stedin">
          <img src={require('./stedin.jpg')} alt="Contact met Stedin" />
        </a>
      </p><p>
        <a href="https://www.enexis.nl/zakelijk/service-en-contact" target="_blank" rel="noopener noreferrer"
          title="Contact met Enexis">
          <img src={require('./enexis.png')} alt="Contact met Enexis" />
        </a>
      </p>
    </div>

    <div className="card">
      <h1>Aanmelden via implementatie partner</h1>
      <p>
        Voor vragen over aansluiten en technische ondersteuning.
      </p>
      <p>
        <a href="https://goo.gl/forms/EuY2jcRuT6kDFi7r1" target="_blank" rel="noopener noreferrer"
          title="Contact met servicedesk energieonderbrekingen">
          <img src={require('./energieonderbrekingen.png')} alt="Contact servicedesk energieonderbrekingen" />
        </a>
      </p>
    </div>




  </div>
)

export default Contact
