import {
  Glyphicon, Table,
} from 'react-bootstrap'
import React from 'react'

import PropTypes from 'prop-types'

function ClientTable(props) {
  return (
    <Table striped bordered condensed hover>
      <thead>
        <tr>
          <th>Naam</th>
          <th>ID</th>
          <td colSpan={2} align="center">
            <a href="clients/create" title="Nieuw" onClick={e => {e.preventDefault(); props.onCreate()}}>
              <Glyphicon glyph="plus" />
            </a>
          </td>
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </Table>
  )
}


ClientTable.propTypes = {
  onCreate: PropTypes.func.isRequired,
}

export default ClientTable
