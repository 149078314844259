import { Row, Col,Panel } from 'react-bootstrap'
import React from 'react'

import PropTypes from 'prop-types'

import {  formatDateTime, formatDate, formatList, dedupList } from '../util'

const formatPC = postcode => {
  const pc = postcode.split(';')

  return pc.length > 1 ? <small style={{color: 'grey'}} title={formatList(postcode)}>Meerdere postcodes</small> : <span title={postcode}>{postcode}</span>
}
const divIt = s => <div key={s}>{s}</div>
const formatDiv = s => dedupList(s).split(';').map(divIt)

const DisruptionPanel = ({eventKey, address, period, source, titel, status, cause}) => {

  const {city, postalCode, street}  = address
  const {plannedBegin, begin, plannedEnd, end, expectedEnd} = period
  const {organisation, id} = source
  const titleSplit = titel && titel.split(' in ')

  titleSplit[1] = dedupList(titleSplit[1])
  const title = titleSplit.join(' in ')

  return (
    <Panel eventKey={eventKey}>
      <Panel.Heading>
        <Panel.Title toggle>
          <Row>
            <Col md={1} sm={2}>{organisation}</Col>
            <Col md={1} sm={2}>{formatDate(begin || plannedBegin)}</Col>
            <Col md={2} sm={4}>{formatPC(postalCode)}</Col>
            <Col md={8} sm={4}>{formatList(title)}</Col>
          </Row>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible>
        <Row>
          <Col md={3}>
            <Row>
              <Col xs={4} lg={4}><strong>Stad</strong></Col>
              <Col xs={3} lg={2}><strong>Postcode</strong></Col>
              <Col xs={5} lg={6}><strong>Straat</strong></Col>
            </Row>
            <Row>
              <Col xs={4} lg={4}>{formatDiv(city)}</Col>
              <Col xs={3} lg={2}>{formatDiv(postalCode)}</Col>
              <Col xs={5} lg={6}>{formatDiv(street)}</Col>
            </Row>
          </Col>
          <Col md={3}>
            <Row> <Col xs={4}><strong>Gepland Begin</strong></Col>  <Col xs={8}>{formatDateTime(plannedBegin)}</Col> </Row>
            <Row> <Col xs={4}><strong>Gepland Einde</strong></Col>  <Col xs={8}>{formatDateTime(plannedEnd)}</Col></Row>
          </Col>
          <Col md={3}>
            <Row> <Col xs={4}><strong>Begin</strong></Col>               <Col xs={8}>{formatDateTime(begin)}</Col> </Row>
            <Row> <Col xs={4}><strong>Einde</strong></Col>               <Col xs={8}>{formatDateTime(end)}</Col></Row>
            <Row> <Col xs={4}><strong>Verwacht einde</strong></Col>      <Col xs={8}>{formatDateTime(expectedEnd)}</Col> </Row>
          </Col>
          <Col md={3}>
            <Row> <Col xs={4}><strong>Identificatienr</strong></Col>     <Col xs={8}>{id}</Col> </Row>
            <Row> <Col xs={4}><strong>Status</strong></Col>              <Col xs={8}>{status}</Col> </Row>
            <Row> <Col xs={4}><strong>Oorzaak</strong></Col>             <Col xs={8}>{cause}</Col> </Row>
          </Col>
        </Row>
      </Panel.Body>
    </Panel>)
}

DisruptionPanel.propTypes = {
  /** EventKey for this panel, so it can be used in an accordion */
  eventKey: PropTypes.number.isRequired,
  /** address information for disruption */
  address: PropTypes.shape({
    street: PropTypes.string,
    postalCode: PropTypes.string.isRequired,
    city: PropTypes.string,
  }).isRequired,
  /** date information about disruption */
  period: PropTypes.shape({
    /** start date/tim, ISO8601  */
    begin: PropTypes.string,
    /** end date/time, ISO8601 */
    end: PropTypes.string,
    /** expected end date/time, ISO8601 */
    expectedEnd: PropTypes.string,
  }).isRequired,
  /** where did the disruption originate */
  source: PropTypes.shape({
    /** Organisation (Enexis, Stedin, Liander) */
    organisation: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    removed: PropTypes.string,
  }).isRequired,

  /** Identification numer */
  id: PropTypes.string.isRequired,
  /** status */
  status: PropTypes.string,
  /** cause of the disruption */
  cause: PropTypes.string

}

export default DisruptionPanel
