import React from 'react'
import { Link } from 'react-router-dom'

import './cards.css'

/**
 * Homepage component, displays information about the platform.
 */
const Home = () => (
  <div className="cards">

    <div className="card">
      <h1>Energieonderbrekingen.nl</h1>
      <p>Is uw bedrijf afhankelijk van elektriciteit of gas voor het leveren van uw product of dienst? Dan wilt u het zo snel mogelijk weten als er een storing is. Hoe eerder hoe liever. </p>
      <p>Om hierin te voorzien zijn drie Nederlandse netbeheerders Enexis Netbeheer B.V., Liander N.V. en Stedin gestart met energieonderbrekingen.nl. Ons doel is: overheden, instellingen en bedrijven snel, volledig en op maat informeren over energieonderbrekingen. Door informatie gestructureerd aan te leveren willen we het telefonie verkeer reduceren.</p>
    </div>

    <div className="card">
      <h1>Data bundelen en delen</h1>
      <p>De Nederlandse netbeheerders bundelen data en delen die met derden. De gedeelde data bevat:</p>
      <ul>
        <li>Actuele (realtime) gegevens over gas- en stroomstoringen. Het gaat om de meldingen van klanten die het Nationaal Storingsnummer hebben gebeld over een storing. De meldingen zijn dus gevalideerd: een medewerker heeft gecheckt of het inderdaad gaat om een storing in het netwerk van de netbeheerders.</li>
        <li>Gegevens over geplande werkzaamheden (Enexis toont deze vanaf het eerste kwartaal van 2020)</li>
      </ul>
    </div>

    <div className="card">
      <h1>Doelgroepen</h1>
      <p>De feeds met informatie zijn bedoeld voor:</p>
      <ul>
        <li> Gemeenten en andere overheden </li>
        <li> Energieleveranciers </li>
        <li> Telecombedrijven </li>
        <li> Beveiligingsbedrijven </li>
        <li> Veiligheidsregio’s </li>
        <li> Overige belanghebbende instanties (na akkoord van de regionale netbeheerder) </li>
      </ul>
    </div>

    <div className="card">
      <h1>Gebruik van data </h1>
      <p>We stellen onze data beschikbaar in de vorm van JSON-feeds. Partijen kunnen de data gebruiken voor hun interne systemen. Daardoor is het bijvoorbeeld mogelijk om medewerkers van een Klantenservice direct te informeren als er een gas- of stroomstoring is. Op hun beurt kunnen deze medewerkers klanten informeren die contact opnemen omdat een product of dienst niet werkt.</p>
      <p>Ziet u mogelijkheden voor verbeteringen of aanvullingen? Wij nodigen andere Nederlandse netbeheerders, serviceproviders en andere partijen van harte uit om hierover mee te denken. Help ons om de kwaliteit van onze data te verbeteren!</p>
    </div>

    <div className="card">
      <h1>Hoe het werkt</h1>
      <p>
        U kunt de <a href="/api/v2" target="_blank" name="documentatie" rel="noopener noreferrer">interface documentatie</a> alvast bekijken en een idee krijgen van ons datamodel.<br/>
        Bent u ge&iuml;nteresseerd in het gebruik van onze data of wilt u ons helpen om de data te verbeteren of aan the vullen? Neem dan <Link to="/contact">contact</Link> op met &eacute;&eacute;n van de netbeheerders. Wij zorgen ervoor dat uw vraag of aanbod bij de juiste persoon terechtkomt en dat hij/zij u hierover terugbelt.
      </p>
    </div>

    <div className="card">
      <h1>Kosten</h1>
      <p>De data wordt kosteloos beschikbaar gesteld.</p>
    </div>

    <div className="card" id="logos">
      <a href="https://storingen-inzicht.web.liander.nl/overzicht" target="_blank" rel="noopener noreferrer"
        title="Storingsoverzicht Liander">
        <img src={require('./alliander.jpg')} alt="Storingsoverzicht Liander" />
      </a>
      &nbsp;
      <a href="https://www.stedin.net/storing-en-onderhoud" target="_blank" rel="noopener noreferrer"
        title="Storingen en Onderhoud Stedin">
        <img src={require('./stedin.jpg')} alt="Storingen en Onderhoud Stedin" />
      </a>
      &nbsp;
      <a href="https://www.enexis.nl/storingen" target="_blank" rel="noopener noreferrer"
        title="Storingen en Onderhoud Enexis">
        <img src={require('./enexis.png')} alt="Storingen en Onderhoud Enexis" />
      </a>
    </div>
    <div className="card">
      <h1>Disclaimer</h1>
      <p>U mag verwachten dat de informatie op deze site leesbaar, begrijpelijk en zo actueel mogelijk is.  Ondanks zorgvuldige samenstelling kan het voorkomen dat de informatie een fout bevat of onvolledig is. Enexis Netbeheer B.V., Liander N.V. en Stedin Netbeheer B.V. garanderen daarom niet de juistheid en  volledigheid van de informatie die op de site wordt gepubliceerd of waartoe via de site toegang wordt geboden. Aansprakelijkheid voor eventuele schade ten gevolge van toegang tot en gebruik van de site wijzen wij dan ook af. Ook bieden we geen garantie voor het foutloos en ononderbroken functioneren van de site.  </p>
    </div>
  </div>
)

export default Home
