import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import PropTypes from 'prop-types'

export function ClientRow({ client, selected, onEdit, onSelect}) {
  return(
    <tr onClick={() => onSelect(client._id)} className={selected ? 'selected' : ''}>
      <td>{client.name}</td>
      <td>{client.client_id}</td>

      <td align="center" style={{ width: '32px' }}>
        <a href={`clients/edit(${client._id})`} title="Bewerken">
          <FontAwesomeIcon icon="edit" onClick={e => {e.preventDefault(); onEdit(client._id)}} />
        </a>
      </td>
    </tr>
  )
}

ClientRow.propTypes = {
  selected     : PropTypes.bool,
  onEdit       : PropTypes.func.isRequired,
  onSelect     : PropTypes.func.isRequired,
  messageCount : PropTypes.number,
  client       : PropTypes.shape({
    _id           : PropTypes.string.isRequired,
    name          : PropTypes.string.isRequired,
    client_id     : PropTypes.string.isRequired,
    client_secret : PropTypes.string.isRequired,
    scopes        : PropTypes.array,
  }),
}

ClientRow.defaultProps = {
  selected     : false,
}

export default ClientRow
