import moment from 'moment'
import 'moment/locale/nl.js'

const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm'
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

const DEBOUNCE_TIMEOUT = 250 // millis

const formatTime = date => date && moment(date).format(TIME_FORMAT)
const formatDate = date => date && moment(date).format(DATE_FORMAT)
const formatDateTime = date => date && moment(date).format(DATE_TIME_FORMAT)
const formatDateTimeSeconds = date => date && moment(date).format(DATE_TIME_FORMAT + ':ss')

const formatList = str => str && str.split(';').join(', ')
const dedup = arr => arr && arr.reduce((acc, curr) => acc.indexOf(curr) > -1 ? acc : acc.concat(curr), [])
const dedupList = str => str && dedup(str.split(';')).join(';')

const emailToName = str => str && str.split('@')[0]

const debounce = (func, wait, immediate) => {
  // see https://gist.github.com/nmsdvid/8807205
  let timeout
  const theWait = wait || DEBOUNCE_TIMEOUT

  return function () {
    const context = this
    const args = arguments

    clearTimeout(timeout)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, theWait)
    if (immediate && !timeout) func.apply(context, args)
  }
}


export {
  debounce,

  DATE_FORMAT,
  TIME_FORMAT,
  formatDate,
  formatDateTime,
  formatDateTimeSeconds,
  formatTime,

  formatList,
  dedup,
  dedupList,

  emailToName,
}
