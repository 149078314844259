import React from 'react'
import {Link} from 'react-router-dom'
import Notifications from './Notifications'
import NotificationPanel from './NotificationPanel'

class Monitor extends React.Component {
  render() {
    const {auth} = this.props

    return auth.isAuthenticated()
      ? <Notifications auth={auth} refresh={this.refresh} />


      : <NotificationPanel
        bsStyle="info"
        glyph="lock"
        kpi="&nbsp;"
        message="Niet ingelogd"
        description={<p>Graag <Link to={'/monitor'} onClick={auth.login}>inloggen</Link> om de monitor te kunnen zien.</p>} />
  }
}


export default Monitor
