import React from 'react'
import PropTypes from 'prop-types'

import NotificationPanel from './NotificationPanel'

/**
 * Panel to display if a miner is online/offline/disconnected.
 * Uses NotificationPanel with some presets.
 *
 * Mapping for status to color:
 *
 * | STATUS       | COLOR  |
 * | ---          | ---    |
 * | online       | green  |
 * | offline      | yellow |
 * | disconnected | red    |
 */
const OnlinePanel = ({status:kpi, dso}) => {
  const style = {
    'online': 'success',
    'offline': 'warning',
    'disconnected': 'danger'
  }[kpi]

  return (
    <NotificationPanel
      bsStyle={style}
      kpi={kpi}
      description={`Online status  miner ${dso}`}
      glyph="off"
    />
  )
}

OnlinePanel.propTypes = {
  /** status of the miner for the dso */
  status: PropTypes.string.isRequired,
  /** name of the dso, the miner is mining for */
  dso: PropTypes.string.isRequired,
}

export default OnlinePanel
