import './disruptions.css'

import { PanelGroup } from 'react-bootstrap'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

import DisruptionPanel from './DisruptionPanel'

class DisruptionsPanelGroup extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired
  }

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeKey: '1'
    }
  }

  onOpen = activeKey => {
    this.setState({activeKey})
  }

  render() {
    return (
      <PanelGroup accordion activeKey={this.state.activeKey} onSelect={this.onOpen} id="panel-disruptions">
        { this.props.data.map((disruption, idx) =>
          <DisruptionPanel key={disruption._id} eventKey={idx}
            address={disruption.location.features.properties}
            {...disruption} />
        )}
      </PanelGroup>
    )
  }
}

export default DisruptionsPanelGroup
