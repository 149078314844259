const optionsNetbeheerder = [
  {value:'Enexis', label: 'Enexis'},
  {value:'Liander', label: 'Liander'},
  {value:'Stedin', label: 'Stedin'}
]

const optionsOpgelost = [
  {value:'yes', label: 'Ja'},
  {value:'no', label: 'Nee'}
]

const optionsNetwerk = [
  {value: 'electricity', label: 'Elektriciteit'},
  {value: 'gas', label: 'Gas'}
]

const optionsSuspectStatus = [
  {value: 'new', label:'Nieuw'},
  {value: 'retry', label: 'Toewijzing herhalen'},
  {value: 'failed', label: 'Toewijzen mislukt'},
  {value: 'assigned', label: 'Toegewezen'},
  {value: 'pending', label: 'In Behandeling'},
  {value: 'confirmed', label: 'Bevestigd'},
  {value: 'denied', label: 'Afgekeurd'}
]

const values = arr => arr.map(elem => elem.value)

export {
  optionsOpgelost,
  optionsNetwerk,
  optionsNetbeheerder,
  optionsSuspectStatus,
  values,
}
