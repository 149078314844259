import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal, Button,
  Table,
  Glyphicon,
} from 'react-bootstrap'

import {
  formatList,
  formatDateTimeSeconds as formatDate,
  dedupList,
} from '../util'

import './HistoryModal.css'

/**
 * Shows a history of one disruption
 */
class HistoryModal extends React.Component {
  /**
   * Creates a new modal
   */
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      id: '',
      historyData: []
    }
  }

  static propTypes = {
    /**  id of the disruption */
    id: PropTypes.string.isRequired,
    /** history data of the disruption */
    data: PropTypes.array,
    /** display the panel when true */
    show: PropTypes.bool,
    /** callback called when panel wants to close */
    onHide: PropTypes.func,
  }

  /**
   * Called when new props are to be received.
   *
   */
  componentWillReceiveProps(nextProps) {
    this.setState({id:nextProps.id, data: nextProps.data || []})
  }

  /**
   * Render the modal.
   * Shows a table of disruption history
   */
  render() {
    const {data} = this.state
    const {organisation, id}  = data.length > 0 ? data[0].source : {}

    return (
      <Modal show={this.props.show} onHide={this.props.onHide}
        dialogClassName="history-modal">
        <Modal.Header>
          <Modal.Title>
            Onderbrekingshistorie {id} van {organisation}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped>
            <thead>
              <tr>
                <th>Bijgewerkt</th>
                <th>Sinds</th>
                <th>Opgelost</th>
                <th>Verwijderd</th>
                <th>Status</th>
                <th>Oorzaak</th>
                <th>Component</th>
                <th>Impact</th>
                <th>Stad</th>
                <th>Postode</th>
                <th>Straat</th>
              </tr>
            </thead>
            <tbody>
              { data.map(d => {
                const addr = d.location.features.properties
                const [beginGlyph, begin] = d.period.begin
                  ? ['alert', formatDate(d.period.begin)]
                  : ['calendar', formatDate(d.period.plannedBegin)]
                const [endGlyph, end] = d.period.end
                  ? ['alert', formatDate(d.period.end)]
                  : ['calendar', formatDate(d.period.plannedEnd)]

                return (
                  <tr key={d.lastUpdated}>
                    <td>{formatDate(d.lastUpdated)}</td>
                    <td>{begin && <Glyphicon glyph={beginGlyph} style={{'color': '#b1b1b1'}}/>} {begin}</td>
                    <td>{end && <Glyphicon glyph={endGlyph} style={{'color': '#b1b1b1'}}/>} {end}</td>
                    <td>{formatDate(d.source.removed)}</td>
                    <td>{d.status}</td>
                    <td>{d.cause}</td>
                    <td>{d.component}</td>
                    <td>{d.impact.min && '> '}{d.impact.max && '< '}{d.impact.households}</td>
                    <td>{formatList(dedupList(addr.city))}</td>
                    <td>{formatList(dedupList(addr.postalCode))}</td>
                    <td>{formatList(dedupList(addr.street))}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <Modal.Footer>
            <Button onClick={this.props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    )
  }
}

export default HistoryModal
