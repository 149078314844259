import React from 'react'

import {
  Grid, Row, Col,
  Jumbotron,
} from 'react-bootstrap'

/**
 * Homepage component, displays information about the platform.
 */
export function LoginPage() {
  return (
    <Grid>
      <Row>
        <Col>
          <Jumbotron>
          Graag inloggen om deze functie te bekijken.
          </Jumbotron>
        </Col>
      </Row>
    </Grid>
  )
}

export function Unauthorized() {
  return (
    <Grid>
      <Row>
        <Col>
          <Jumbotron>
          Onvoldoende rechten voor het bekijken van deze functie
          </Jumbotron>
        </Col>
      </Row>
    </Grid>
  )
}

export const createHasScope = (auth, scope) => Component => props => {
  if (!auth.isAuthenticated()) return <LoginPage />
  if (!auth.userHasScopes([scope])) return <Unauthorized />

  return <Component {...props} />
}


