import React from 'react'
import PropTypes from 'prop-types'

import loading from '../auth/loading.svg'

import NotificationPanel from './NotificationPanel'

const LoadingPanel = ({ glyph, description }) =>
  <NotificationPanel glyph={glyph}  kpi={<img src={loading} style={{height: '50px'}} alt="laden..."/>} description={description} />

LoadingPanel.propTypes = {
  /** A bootstrap glyphicon */
  glyph: PropTypes.string,
  /** Descriptons of the panel */
  description: PropTypes.string
}

LoadingPanel.defaultProps = {
  glyph: 'refresh',
  description: 'laden...'
}

export default LoadingPanel
