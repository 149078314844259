import 'bootstrap/dist/css/bootstrap.min.css'

import React from 'react'
import ReactDOM from 'react-dom'

import {makeMainRoutes} from './routes'
import register, { unregister} from './registerServiceWorker'

const routes = makeMainRoutes()

ReactDOM.render(routes, document.getElementById('root'))

if (window.location.pathname.startsWith('/api')) {
  console.info('unregistering service worker for api route') // eslint-disable-line 
  unregister()
  window.location.reload()
} else {
  register()
}


if (module.hot) {
  module.hot.accept('./routes', () => {
    const NextRoutes = require('./routes').default

    ReactDOM.render(<NextRoutes />, document.getElementById('root'))
  })
}

