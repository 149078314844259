import React from 'react'
import { Alert, Button, Grid, Panel, Row, Table } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import Select from 'react-select'

import RateSuspectModal from './RateSuspectModal'

import { debounce, formatDateTime } from '../util'
import {
  optionsSuspectStatus, optionsNetwerk, values, optionsNetbeheerder
} from '../dropdowns/options'

const ONE_THOUSAND_MILLIS = 1000
const MAX_SECONDS = 10
const MINUTE_SECONDS = 60
const HOUR_MINUTES = 60
const TWO_DIGIT_MINIMIUM = 10

function diff(from, to) {
  if (!from || !to) {
    return ''
  }

  try {
    let seconden = moment(to).diff(from, 's')
    let minuten = moment(to).diff(from, 'm')
    let uren = moment(to).diff(from, 'h')


    if (minuten > 0)
      seconden = seconden - minuten * MINUTE_SECONDS
    if (uren > 0)
      minuten = minuten - uren * HOUR_MINUTES

    if (minuten < TWO_DIGIT_MINIMIUM)
      minuten = '0' + minuten

    if (seconden < TWO_DIGIT_MINIMIUM)
      seconden = '0' + seconden

    return `${uren}:${minuten}:${seconden}`

  } catch (err) {
    console.log(err) // eslint-disable-line 
    // ignore..

    return '…'
  }
}

class Suspects extends React.Component {

  defaults = {
    dso: '',
    network: values(optionsNetwerk),
    status: ''
  }

  state = {
    ...this.defaults,
    suspectData: [],
    disruptionId: '',
    org: '',
    showModal: false,
    selectedSuspect: {},
    editMode: false,
    currentDateSortOrder: 'none'
  }

  componentDidMount() {
    this.setState({status: ['assigned'], showDuration: false, error: false}, this.startPolling)
  }

  componentWillUnmount() {
    this.stopPolling()
  }

  startPolling = () => {
    this.query()
    this.timer = setInterval(this.query, MAX_SECONDS * ONE_THOUSAND_MILLIS)
  }

  stopPolling = () => clearInterval(this.timer)

  query = debounce(() => {
    const { isAuthenticated, getAccessToken } = this.props.auth

    if(isAuthenticated()) {
      const headers = { 'Authorization': `Bearer ${getAccessToken()}`}
      const { network, status, dso } = this.state

      const params = { status, network, dso }

      axios.get('api/dash/suspects', {headers, params})
        .then(response => this.setState({suspectData: response.data.suspects, error: false, fetched: moment()}))
        .catch(error => this.setState({ error }))
    }
  })

  toggleDuration = () => {
    this.setState(({showDuration}) => ({showDuration: !showDuration}))
  }

  onChangeDropdown = prop => selectedOption => {
    let value = this.defaults[prop]

    if (selectedOption) {
      if (Array.isArray(selectedOption)) {
        if (selectedOption.length > 0) {
          value = values(selectedOption)
        }
      } else {
        value = selectedOption.value
      }
    }

    this.setState({[prop] : value }, this.query)
  }

  onChangeDsoRefValue = event => this.setState({disruptionIdForHandlingSuspect: event.target.value})

  showDetails = selectedSuspect => {
    if (!selectedSuspect) return
    this.stopPolling()
    this.setState({selectedSuspect, showModal: true, editMode: false })
  }
  showRate = selectedSuspect => {
    if (!selectedSuspect) return
    this.stopPolling()
    this.setState({selectedSuspect, showModal: true, editMode: true })
  }

  closeModal = () => {
    this.setState({showModal: false}, this.startPolling)
  }

  formatStatus = ({status}) => {
    const found = optionsSuspectStatus.find(x => x.value === status)

    return found ? found.label : ''
  }

  formatAdres = ({address}) => {
    const {postalCode, houseNumber, houseNumberAddition} = address

    return <span>{postalCode}&nbsp;{houseNumber}&nbsp;{houseNumberAddition}</span>
  }

  formatNetwork = ({network}) => {
    const found = optionsNetwerk.find(x => x.value === network)

    return  found ? found.label : ''
  }

  rateSuspect = ({id, disruptionId, status}) => {
    const { isAuthenticated, getAccessToken } = this.props.auth

    if(isAuthenticated()) {
      const headers = { 'Authorization': `Bearer ${getAccessToken()}`}
      const body = { disruptionId, status }

      axios.patch(`api/dash/suspects/${id}`, body, {headers})
        .then(() => {
          const {suspectData} = this.state
          const newSuspectData = suspectData.map(suspect => {
            if (suspect._id === id) {
              return {...suspect, status, disruptionId}
            }

            return suspect
          })

          this.setState({suspectData:newSuspectData, showModal:false, error: false}, this.startPolling)
        })
        .catch(error => this.setState({error}))
    }
  }

  confirm = id => disruptionId => this.rateSuspect({id, disruptionId, status:'confirmed'})
  deny = id => () => this.rateSuspect({id, status:'denied'})

  render() {
    const {userHasScopes, getExpiresAt} = this.props.auth
    const {network, status, suspectData, error, showDuration} = this.state
    const dsos = this.props.auth.getUserDso()
    const multiDso = dsos.length > 1
    const isEditor = userHasScopes(['update:suspect'])

    return (
      <Grid fluid>
        <div>

          <Panel bsStyle="primary">
            <Panel.Heading>
              <Panel.Title componentClass="h3">Veronderstelde onderbrekingen in elektriciteit- en gasnetwerk.<span className="small pull-right">Sessie geldig tot {formatDateTime(getExpiresAt())}</span></Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              { error && <Row style={{paddingLeft: 20, paddingRight: 20}}><Alert bsStyle="danger">{error.message}</Alert></Row> }
              <Row>
                <Table id="suspectTable" striped responsive style={{minHeight: 400}}>
                  <thead>
                    <tr>
                      {multiDso && <th>Netbeheerder</th> }
                      <th>Netwerk</th>
                      <th>Status</th>
                      <th>Adres</th>
                      <th>Referentienummer</th>
                      <th>Creatiedatum</th>
                      <th style={showDuration ? {textAlign:'right'} : {}}>Toegewezen</th>
                      <th style={showDuration ? {textAlign:'right'} : {}}>Afgesloten</th>
                      <th>Indiener</th>
                      <th>Acties</th>
                    </tr>
                    {
                      <tr>
                        { multiDso &&
                        <th>
                          <Select id="dso" className="dso" multi={true} options={optionsNetbeheerder} value={this.state.dso} onChange={this.onChangeDropdown('dso')} />
                        </th>
                        }
                        <th>
                          <Select id="network" className="network" multi={true}
                            value={network} options={optionsNetwerk}
                            onChange={this.onChangeDropdown('network')}/>
                        </th>

                        <th>
                          <Select id="status" className="status" multi={true}
                            value={status} options={optionsSuspectStatus}
                            onChange={this.onChangeDropdown('status')} />
                        </th>
                        <th> &nbsp; </th>
                        <th> &nbsp; </th>
                        <th> &nbsp; </th>
                        <th> &nbsp; </th>
                        <th> &nbsp; </th>
                        <th> &nbsp; </th>
                        <th><Button onClick={this.toggleDuration}>{this.state.showDuration ? 'Toon tijd' : 'Toon doorloop'}</Button></th>
                      </tr>
                    }
                  </thead>
                  <tbody>
                    {
                      suspectData.map((suspect) =>
                        <tr key={suspect._id}>
                          {multiDso && <td>{suspect.dso}</td> }
                          <td>{this.formatNetwork(suspect)}</td>
                          <td>{this.formatStatus(suspect)}</td>
                          <td>{this.formatAdres(suspect)}</td>
                          <td>{suspect.disruptionId}</td>
                          <td style={{width: '10em'}}>{formatDateTime(suspect.created)}</td>
                          { showDuration
                            ? <td style={{width: '10em', textAlign: 'right'}}>{diff(suspect.created, suspect.assigned)}</td>
                            : <td style={{width: '10em'}}>{formatDateTime(suspect.assigned)}</td>
                          }
                          { showDuration
                            ? <td style={{width: '10em', textAlign: 'right'}}>{diff(suspect.created, suspect.closed)}</td>
                            : <td style={{width: '10em'}}>{formatDateTime(suspect.closed)}</td>
                          }
                          <td>{suspect.organisation}</td>
                          <td>{ (isEditor && suspect.status === 'assigned')
                            ? <Button bsStyle="success" onClick={ () => this.showRate(suspect) }>Afhandelen</Button>
                            : <Button bsStyle="info" onClick={ () => this.showDetails(suspect) }>Details</Button>
                          }</td></tr>
                      )
                    }
                  </tbody>
                </Table>
              </Row>
            </Panel.Body>
          </Panel>
        </div>
        <RateSuspectModal
          show={this.state.showModal}
          suspect={this.state.selectedSuspect}
          editMode={this.state.editMode}

          onHide={this.closeModal}
          confirm={this.confirm(this.state.selectedSuspect._id)}
          deny={this.deny(this.state.selectedSuspect._id)}
        />
      </Grid>
    )
  }
}

export default Suspects
