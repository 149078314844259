import React from 'react'
import {FormGroup, ControlLabel} from 'react-bootstrap'
import Select from 'react-select'
import 'react-select/dist/react-select.css'

const Dropdown = ({id, label, ...props}) => (
  <FormGroup controlId={id}>
    <ControlLabel>{label}</ControlLabel>
    <Select {...props} />
  </FormGroup>
)

export default Dropdown
