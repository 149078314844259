import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Checkbox,
  ControlLabel,
  FormControl,
  FormGroup,
  Glyphicon,
  HelpBlock,
  InputGroup,
  Modal,
  Panel,
} from 'react-bootstrap'
import copy from 'copy-to-clipboard'

const pullServices = [
  {
    name: 'disruptions',
    scopes: [ 'read:disruptions', 'read:history']
  },
  {
    name: 'suspects',
    scopes: [ 'read:dso_liander', 'read:dso_enexis', 'read:dso_stedin',
      'create:suspect', 'read:suspect', 'update:suspect' ]
  }

]

class ClientForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name:'',
      scopes: []
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.client !== prevProps.client) {
      this.setState({ scopes: [], ...this.props.client, submitted: false })
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value.trimStart() })

  handleSelect = prop => value => this.setState({ [prop]: value })

  validateProp = prop => {
    const length = this.state[prop].length

    if (length === 0) {
      return this.state.submitted ? 'error' : null
    }

    return 'success'
  }

 toggleScope = scope => {
   const {scopes} = this.state
   let newScopes

   if (scopes.includes(scope)) {
     newScopes = scopes.filter(s => s !== scope)
   } else {
     newScopes = scopes.concat([scope])
   }

   this.setState({scopes: newScopes})
 }

  onSubmit = e => {
    const isValid =
      ['name']
        .map(this.validateProp)
        .reduce((acc, cur) => (acc && cur === 'success'), true)

    if (!isValid) {
      return this.setState({ submitted: true })
    }

    const { _id, name, scopes } = this.state

    this.props.onSubmit({ _id, name, scopes })
  }

  renderPullService = ({name, scopes}) => {
    const clientScopes = this.state.scopes

    return (
      <FormGroup controlId={name} key={name}>
        <InputGroup>
          <ControlLabel>{name}</ControlLabel>
          {
            scopes.map(scope => {
              const hasScope = clientScopes.includes(scope)


              return <Checkbox key={`${name}-${scope}`} checked={hasScope} onChange={() => this.toggleScope(scope)} > {scope} </Checkbox>
            })
          }
        </InputGroup>
      </FormGroup>
    )}



  render() {
    const {
      _id,
      name,
      client_id,
      client_secret,
    } = this.state

    return (

      this.props.show
        ?  <div className="static-modal">
          <Modal.Dialog>
            <Modal.Header>
              <Modal.Title>{_id ? 'Client bewerken' : 'Client aanmaken'}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <form>
                <FormGroup controlId="name" validationState={this.validateProp('name')} >
                  <ControlLabel>Naam</ControlLabel>
                  <FormControl type="text" name="name" value={name} placeholder="Naam" onChange={this.handleChange} />
                  <FormControl.Feedback />
                  { _id ? false : <HelpBlock>Client naam en scopes zijn later nog aan te passen</HelpBlock> }
                </FormGroup>

                {_id &&
                    <React.Fragment>
                      <FormGroup controlId="client_id">
                        <ControlLabel>Client ID</ControlLabel>
                        <InputGroup>
                          <FormControl type="text" name="client_id" value={client_id} disabled={true} />
                          <Button componentClass={InputGroup.Addon} onClick={() => copy(client_id)}><Glyphicon glyph="copy"/></Button>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup controlId="client_secret">
                        <ControlLabel>Client Secret</ControlLabel>
                        <InputGroup>
                          <FormControl type={this.state.showSecret ? 'text' : 'password' }  name="client_secret" value={client_secret} disabled={true}  />
                          <Button componentClass={InputGroup.Addon} onClick={() => this.setState(state => ({...state, showSecret: !state.showSecret}))}>
                            <Glyphicon glyph={this.state.showSecret ? 'eye-close' : 'eye-open'} />
                          </Button>
                          <Button componentClass={InputGroup.Addon} onClick={() => copy(client_secret)}><Glyphicon glyph="copy"/></Button>
                        </InputGroup>
                      </FormGroup>


                      <Panel>

                        <Panel.Heading>Pull Services</Panel.Heading>
                        <Panel.Body>
                          { pullServices.map(this.renderPullService) }
                        </Panel.Body>
                      </Panel>

                    </React.Fragment>
                }

              </form>
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={this.props.onClose}>Sluiten</Button>
              <Button bsStyle="success" className="pullRight" onClick={this.onSubmit}>Opslaan</Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
        : false
    )}
}

ClientForm.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  client: PropTypes.shape({
    _id: PropTypes.string,
  })
}

ClientForm.defaultProps = {
  show: false,
}

export default ClientForm
