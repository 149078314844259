import './Notifications.css'

import { Row, Col } from 'react-bootstrap'
import React from 'react'
import axios from 'axios'

import PropTypes from 'prop-types'

import ConnectionPanel from './ConnectionPanel'
import LoadingPanel from './LoadingPanel'
import OnlinePanel from './OnlinePanel'
import UpdatePanel from './UpdatePanel'

const QUERY_INTERVAL = 90000

/**
 * Shows several notifications based on availability and user scope.
 */
class Notifications extends React.Component {
  static propTypes = {
    refresh: PropTypes.func,
    auth: PropTypes.shape({
      isAuthenticated: PropTypes.func,
      getAccessToken: PropTypes.func,
      userHasScopes: PropTypes.func,
      getUserDso: PropTypes.func,
    }).isRequired,
  }

  state = {
    data: []
  }

  /**
   * Call query and set an interval for it
   */
  componentDidMount() {
    this.query()
    this.interval = setInterval(this.query, QUERY_INTERVAL)
  }

  /**
   * Clear interval
   */
  componentWillUnmount() {
    clearInterval(this.interval)
  }

  /**
   * Query the backend for the state of the notifications (if authorized).
   * Add the data to the state (or an error)
  */
  query = () => {
    const { isAuthenticated, getAccessToken, logout} = this.props.auth

    if (!isAuthenticated()) {
      logout()

      return
    }

    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}

    axios('/api/dash/miners', { headers })
      .then(response => this.setState({data:response.data, error: false}))
      .catch(error => this.setState({error}))
  }

  /**
   * Render the notifications.
   * For each DSO the user is authorized for there will be one row with notifications.
   */
  render() {
    const { isAuthenticated, userHasScopes } = this.props.auth

    if (!isAuthenticated())
      return <div />

    const hasMqttStatus = userHasScopes(['read:mqtt_status'])
    const width = hasMqttStatus ? 4 : 6 // eslint-disable-line

    const notifications = this.state.data.map(({count, querySuccess, lastUpdated, status, dso}) =>{
      const dsor = dso && dso.replace('gepland', '(geplande werkzaamheden)')

      return (
        <Row key={dso}>
          { hasMqttStatus &&
          <Col sm={width}>
            <OnlinePanel
              status={status}
              dso={dsor} />
          </Col>
          }
          <Col sm={width}>
            <ConnectionPanel
              datetime={querySuccess}
              dso={dsor}/>
          </Col>
          <Col sm={width}>
            <UpdatePanel
              updates={count}
              datetime={lastUpdated}
              dso={dsor}/>
          </Col>
        </Row>
      )})

    return (
      notifications.length > 0
        ? notifications
        : <Row><Col sm={6}><LoadingPanel /></Col><Col sm={6}><LoadingPanel/></Col></Row>
    )
  }
}

export default Notifications
