import {
  Row, Col,
  Panel,
  Glyphicon,
} from 'react-bootstrap'
import React from 'react'

import PropTypes from 'prop-types'

/**
 * Generic panel for a notification.
 */
const NotificationPanel = ({bsStyle, glyph, kpi, message, description, title}) => (
  <Panel bsStyle={bsStyle} className="notification-panel" title={title}>
    <Panel.Heading>
      <Row>
        <Col xs={3}>
          <Glyphicon glyph={glyph}/>
        </Col>
        <Col className="text-right" xs={9}>
          <div className="huge">{kpi}</div>
          <div>{message}</div>
        </Col>
      </Row>
    </Panel.Heading>
    <Panel.Footer>
      <span>{description}</span>
    </Panel.Footer>
  </Panel>
)



NotificationPanel.propTypes = {
  /** Bootstrap to apply to the whole panel */
  bsStyle: PropTypes.oneOf(['primary', 'success', 'info', 'warning', 'danger']),
  /** Somevalue that's being monitored */
  kpi: PropTypes.any.isRequired,
  /** description related to the current kpi */
  message: PropTypes.any,
  /** general description of the notification/kpi */
  description: PropTypes.any.isRequired,
  /** A bootstrap glyphicon */
  glyph: PropTypes.string.isRequired,
}

export default NotificationPanel
