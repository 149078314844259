import React from 'react'
import PropTypes from 'prop-types'
import {
  NavDropdown,
  Row, Col,
  Button,
} from 'react-bootstrap'

import {emailToName} from '../util'

/**
 * profile and logout are mandatory (see propTypes below)
 * thus do not render if either is not provided
 */
const ProfileDropdown = ({profile, logout}) => (
  <NavDropdown id="profile-dropdown" title={<img src={profile.picture} alt="profile"/>}>
    <div className="navbar-login">
      <Row>
        <Col lg={4}>
          <p className="text-center">
            <img src={profile.picture} alt="profile" height="90"/>
          </p>
        </Col>
        <Col lg={8}>
          <p className="text-left"><strong>{emailToName(profile.name)}</strong></p>
          <p className="text-left small">{profile.email}</p>
        </Col>
      </Row>
    </div>
    <div className="navbar-login navbar-login-session">
      <Row>
        <Col lg={12}>
          <Button id="logout" bsStyle="danger" onClick={logout}>Uitloggen</Button>
        </Col>
      </Row>
    </div>
  </NavDropdown>
)

ProfileDropdown.propTypes = {
  /** profile of the user */
  profile: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    /** Url to a profile picture */
    picture: PropTypes.string,
  }).isRequired,

  /** logout callback, for when the logout button is pressed */
  logout: PropTypes.func.isRequired
}

export default ProfileDropdown
