import React from 'react'
import PropTypes from 'prop-types'

import './Notifications.css'
import NotificationPanel from './NotificationPanel'

import moment from 'moment'
import { TIME_FORMAT } from '../util'

/**
 * Panel to display a connection status.
 * Uses NotificationPanel with some presets.
 *
 * The time is displayed in green when within the last hour, red otherwise.
 */
const ConnectionPanel = props => {
  // eslint-disable-next-line
  const hour = 60 * 60 * 1000 // millis
  const dt = moment(props.datetime || '1970-01-01T00:00:00')
  const time = dt.format(TIME_FORMAT)
  const diff = moment().diff(dt)
  const style = diff < hour ? 'success' : 'danger'

  return (
    <NotificationPanel
      bsStyle={style}
      description={`Verbindingsstatus ${props.dso}`}
      kpi={time}
      message="Laaste succesvolle aanroep"
      glyph="link"
    />
  )
}

ConnectionPanel.propTypes = {
  /** last known date and time the connection was succesfull */
  datetime: PropTypes.string.isRequired,
  /** name of the dso, owner of the service the connection was made to */
  dso: PropTypes.string.isRequired,
}
export default ConnectionPanel
