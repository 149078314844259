import 'moment/locale/nl.js'
import 'react-datetime/css/react-datetime.css'

import './dashboard.css'

import {
  Grid, Panel, Table,
  FormControl,
  ButtonToolbar,
  ButtonGroup, Button,
  ToggleButtonGroup, ToggleButton,
  Tooltip, OverlayTrigger,
  Alert, Row, Col,
} from 'react-bootstrap'
import Datetime from 'react-datetime'
import React from 'react'
import Select from 'react-select'
import axios from 'axios'
import moment from 'moment'

import {debounce, formatDate, formatDateTime, DATE_FORMAT} from '../util'
import {
  optionsNetwerk, optionsNetbeheerder, values,
} from '../dropdowns/options'
import Notifications from '../notification/Notifications.js'
import loading from '../auth/loading.svg'

import HistoryModal from './HistoryModal'

const networks = {
  'electricity': 'Elektriciteit',
  'gas': 'Gas'
}

const TWO_DAYS_AGO = moment().subtract(2, 'days') // eslint-disable-line
const MONTH_AGO = moment().subtract(30, 'days') // eslint-disable-line
const ONE_THOUSAND_MILLIS = 1000
const MAX_SECONDS = 60

/**
 * Tooltip template for date selection.
 *
 * @param {string} field create tooltip for this field
*/
const dateTooltip = field => (
  <Tooltip id={field}>
    Filter onderbrekingen met een {field} <strong>voor</strong> geselecteerde datum.
  </Tooltip>
)

/**
 * TODO document
 */
class DisruptionsOpen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.defaults,
      error: false,
      data: [],
      option_filter: true,
      fetched: false,
      fetched_formatted: '',
    }
  }

  defaults = {
    // client side filters
    id: '',
    removed: false,

    // server side filters
    dso: values(optionsNetbeheerder),
    network: values(optionsNetwerk),
    begin: TWO_DAYS_AGO,
    lastUpdated: TWO_DAYS_AGO,
  }

  componentDidMount() {
    this.query()
    this.interval = setInterval(this.updateFetchedFormat, ONE_THOUSAND_MILLIS)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  updateFetchedFormat = () => {
    const {fetched} = this.state

    if (fetched) {
      const diff = Math.ceil(moment().diff(fetched) / ONE_THOUSAND_MILLIS)
      const wanneer = diff < MAX_SECONDS
        ? `${diff} seconden geleden`
        : this.state.fetched.fromNow()

      this.setState({fetched_formatted: `De data is ${wanneer} opgehaald.`})
    }
  }


  query = debounce(() => {
    const { isAuthenticated, getAccessToken} = this.props.auth

    if (!isAuthenticated())
      return

    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}

    const { dso, network, begin, lastUpdated } = this.state
    const params = {dso, network, begin:formatDate(begin), lastUpdated:formatDate(lastUpdated)}

    axios.get('/api/dash/open', { params, headers })
      .then(response => this.setState({data:response.data, error: false, fetched: moment()}))
      .catch(error => this.setState({error}))
  })

  onChangeDropdown = prop => selectedOption => {
    let value = this.defaults[prop]

    if (selectedOption) {
      if (Array.isArray(selectedOption)) {
        if (selectedOption.length > 0) {
          value = values(selectedOption)
        }
      } else {
        value = selectedOption.value
      }
    }

    this.setState({[prop] : value }, this.refresh)
  }

  onDateChange = prop => date => this.setState({[prop]:date}, this.refresh)

  isValidDate = current => current.isBefore() && current.isAfter(MONTH_AGO)// leave args blank, defaults to now

  onChangeIdFilter = event => this.setState({id : event.target.value })

  toggleState = prop => () => this.setState({[prop]: !this.state[prop]})

  resetForm = () => this.setState({...this.defaults}, this.refresh)

  refresh = () => this.setState({error: false, fetched: false}, this.query)

  getHistory = id => {
    const { isAuthenticated, getAccessToken} = this.props.auth

    if (!isAuthenticated())
      return

    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}

    axios('/api/dash/history/' + id, { headers })
      .then(response => this.setState({historyData:response.data, historyError: false}))
      .catch(historyError => this.setState({historyError}))
  }
  showHistory = d  => {
    this.setState({showModal: true, historyId: d._id, historyData: []})
    this.getHistory(d._id)
  }

  hideHistory = () => this.setState({showModal: false})


  handleSelect = disruption => event => {
    event.preventDefault()
    this.showHistory(disruption)
  }

  render() {
    const dsos = this.props.auth.getUserDso()
    const singleDso = dsos.length === 1 ? dsos[0] : false

    const {
      dso, network, data,
      fetched, fetched_formatted, error:err,
      showModal, historyId = '', historyData,
    } = this.state

    const { auth } = this.props

    const filteredData = data.filter(d => d.source.id.indexOf(this.state.id) > -1)
      // either do not filter or disruption has a removed date
      .filter(d => !this.state.removed || d.source.removed)

    let error = err

    if (err.response && err.response.data && err.response.data.message) {
      error = err.response.data
    }

    return (
      <Grid fluid>
        <Notifications auth={this.props.auth} />
        <HistoryModal show={showModal} onHide={this.hideHistory} id={historyId} data={historyData} />
        <Row>
          <Col md={12}>
            <Panel bsStyle="primary">
              <Panel.Heading>
                <Panel.Title componentClass="h3"> Openstaande onderbrekingen {singleDso} </Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <Row>
                  <Col md={6}>
                    <ButtonToolbar>
                      <ToggleButtonGroup type="checkbox" value={this.state.option_filter ? [1] : []} onChange={this.toggleState('option_filter')}>
                        <ToggleButton value={1}>Filters tonen</ToggleButton>
                      </ToggleButtonGroup>
                      <ButtonGroup>
                        <Button onClick={this.resetForm}>Herstellen</Button>
                        <Button onClick={this.refresh}>Verversen</Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Col>
                  <Col md={6}>
                    { error ?
                      <Alert bsStyle="danger">{error.message}</Alert>
                      :
                      <span className="pull-right">{
                        fetched
                          ? <span>{fetched_formatted}<br/>{filteredData.length} resultaten</span>
                          : <img style={{height: '2em'}} src={loading} alt="loading"/> }
                      </span>
                    }
                  </Col>
                </Row>
                <hr/>
                {
                  data.length === 0 ? (!fetched && !error && <img src={loading} alt="loading"/>) :
                    <Table id="disropen" striped responsive>
                      <thead>
                        <tr>
                          <th>Identificatienummer</th>
                          {!singleDso &&
                              <th>Netbeheerder</th>
                          }
                          <th>Netwerk</th>
                          <th>Startdatum</th>
                          <th>Bijwerkdatum</th>
                          <th>Verwacht einde</th>
                          <th>Verwijderdatum</th>
                        </tr>
                        { this.state.option_filter &&
                            <tr>
                              <th><FormControl value={this.state.id} type="text" placeholder="Filter identificatienummer" onChange={this.onChangeIdFilter} /></th>
                              {!singleDso &&
                                  <th><Select id="dso" className="dso" multi={true} value={dso} options={optionsNetbeheerder} onChange={this.onChangeDropdown('dso')} /> </th>
                              }
                              <th><Select id="netwerk"  className="network" multi={true} value={network} options={optionsNetwerk} onChange={this.onChangeDropdown('network')} /> </th>
                              <th>
                                <OverlayTrigger placement="left" overlay={dateTooltip('startdatum')}>
                                  <Datetime dateFormat={DATE_FORMAT} timeFormat={false} isValidDate={this.isValidDate} value={this.state.begin}       onChange={this.onDateChange('begin')} />
                                </OverlayTrigger>
                              </th>
                              <th>
                                <OverlayTrigger placement="top" overlay={dateTooltip('bijwerkdatum')}>
                                  <Datetime dateFormat={DATE_FORMAT} timeFormat={false} isValidDate={this.isValidDate} value={this.state.lastUpdated} onChange={this.onDateChange('lastUpdated')} />
                                </OverlayTrigger>
                              </th>
                              <th>&nbsp;</th>
                              <th>
                                <ToggleButtonGroup type="checkbox" value={this.state.removed ? [1] : []} onChange={this.toggleState('removed')}>
                                  <ToggleButton value={1} >
                                    Alleen verwijderd
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </th>
                            </tr>
                        }
                      </thead>
                      <tbody>
                        {
                          filteredData
                            .map(disruption => (
                              <tr key={disruption.source.id}>
                                <td>
                                  {
                                    auth.userHasScopes(['read:history'])
                                      ? <a href={disruption.source.id} onClick={this.handleSelect(disruption)}>{disruption.source.id}</a>
                                      : disruption.source.id
                                  }
                                </td>
                                {!singleDso &&
                                    <td>{disruption.source.organisation}</td>
                                }
                                <td>{networks[disruption.network.type]}</td>
                                <td>{formatDateTime(disruption.period.begin)}</td>
                                <td>{formatDateTime(disruption.lastUpdated)}</td>
                                <td>{formatDateTime(disruption.period.expectedEnd)}</td>
                                <td>{formatDateTime(disruption.source.removed)}</td>
                              </tr>
                            ))
                        }
                      </tbody>
                    </Table>
                }
              </Panel.Body>
            </Panel>
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default DisruptionsOpen
