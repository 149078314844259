import React from 'react'
import PropTypes from 'prop-types'

import axios from 'axios'

import moment from 'moment'
import 'moment/locale/nl.js'

import {
  Panel, Table,
  Grid, Row, Col,
  Glyphicon,
} from 'react-bootstrap'

import {formatTime, formatDate} from '../util'
import HistoryModal from './HistoryModal'

/**
 * Display panels with what's happening today
 */
class PanelToday extends React.Component {
  static defaultProps = {
    filter: (()=>true),
    data: [],
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    filter: PropTypes.func,
    data: PropTypes.array.isRequired,
  }

  handleSelect = disruption => event => {
    event.preventDefault()
    this.props.onSelect(disruption)
  }

  render() {
    const {
      title, data, filter,
      showEnded, showRemoved,
      auth,
    } = this.props

    const dsos = this.props.auth.getUserDso()
    const singleDso = dsos.length === 1 ? dsos[0] : false


    return (
      <Panel bsStyle={this.props.bsStyle}>
        <Panel.Heading >
          <Panel.Title componentClass="h3">{title}</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Table striped responsive>
            <thead>
              <tr>
                <th>Id</th>
                { !singleDso &&
                <th>NB</th>
                }
                <th>Sinds</th>
                <th>Bijgewerkt</th>
                { showEnded &&
                <th>Opgelost</th>
                }
                { showRemoved &&
                <th>Verwijderd</th>
                }
              </tr>
            </thead>
            <tbody>
              {
                data
                  .filter(filter)
                  .map(disruption => {
                    const glyph = disruption.planned  || disruption.period.plannedBegin ? 'calendar' : 'alert'
                    const begin = disruption.period.begin
                      ? formatDate(disruption.period.begin)
                      : formatDate(disruption.period.plannedBegin)

                    return (
                      <tr key={disruption.source.id}>
                        <td>
                          {
                            auth.userHasScopes(['read:history']) ?
                              <a href={disruption.source.id} onClick={this.handleSelect(disruption)}>{disruption.source.id}</a>
                              :
                              disruption.source.id
                          }
                        </td>
                        { !singleDso &&
                            <td>{disruption.source.organisation}</td>
                        }
                        <td><Glyphicon glyph={glyph} style={{'color': '#b1b1b1'}}/> {begin}</td>
                        <td>{formatTime(disruption.lastUpdated)}</td>
                        { showEnded &&
                            <td>{
                              disruption.period.plannedEnd
                                ? formatDate(disruption.period.plannedEnd)
                                : formatDate(disruption.period.end)
                            }</td>
                        }
                        { showRemoved &&
                            <td>{formatTime(disruption.source.removed)}</td>
                        }
                      </tr>
                    ) })
              }
            </tbody>
          </Table>

        </Panel.Body>
      </Panel>
    )
  }
}

class DisruptionsToday extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      showModal: false,
      historyId: '',
      historyData: []
    }
  }

  componentDidMount() {
    this.query()
  }

  query = () => {
    const { isAuthenticated, getAccessToken} = this.props.auth

    if (!isAuthenticated())
      return

    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}

    axios('/api/dash/today', { headers })
      .then(response => this.setState({data:response.data, error: false, fetched: moment()}))
      .catch(error => this.setState({error}))
  }

  getHistory = id => {
    const { isAuthenticated, getAccessToken} = this.props.auth

    if (!isAuthenticated())
      return

    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}

    axios('/api/dash/history/' + id, { headers })
      .then(response => this.setState({historyData:response.data, historyError: false}))
      .catch(historyError => this.setState({historyError}))
  }

  exists = s => s && s.length > 0
  today = dt => moment(dt).isSame(moment(), 'd')
  beforeNow = dt => moment(dt).diff(moment()) < 0
  ended = d => this.exists(d.period.end) || this.beforeNow(d.period.plannedEnd)
  endedToday = d => this.ended(d) && (this.today(d.period.end) || this.today(d.period.plannedEnd))
  endedEarlier = d => this.ended(d) && !this.endedToday(d)
  removed = d => this.exists(d.source.removed) && !this.ended(d)
  open = d => !this.removed(d) && !this.ended(d)

  showHistory = d  => {
    this.setState({showModal: true, historyId: d._id, historyData: []})
    this.getHistory(d._id)
  }
  hideHistory = () => this.setState({showModal: false})


  render() {
    const { data, showModal, historyId, historyData } = this.state
    const { auth } = this.props

    return (
      <div>
        <HistoryModal show={showModal} onHide={this.hideHistory} id={historyId} data={historyData} />
        <Grid fluid>
          <Row>
            <Col lg={4} md={6}>
              <Row>
                <Col lg={12} md={12}>
                  <PanelToday
                    auth={auth}
                    title="Openstaand, vandaag bijgewerkt"
                    filter={this.open}
                    onSelect={this.showHistory}
                    data={data} />
                </Col>
                <Col lg={12} md={12}>
                  <PanelToday
                    auth={auth}
                    bsStyle="warning"
                    title="Openstaand, vandaag verwijderd"
                    filter={this.removed}
                    showRemoved={true}
                    onSelect={this.showHistory}
                    data={data} />
                </Col>
              </Row>
            </Col>
            <Col lg={8} md={6}>
              <Row>
                <Col lg={6} md={12}>
                  <PanelToday
                    auth={auth}
                    bsStyle="success"
                    title="Opgelost vandaag"
                    filter={this.endedToday}
                    showEnded={true}
                    onSelect={this.showHistory}
                    data={data} />
                </Col>
                <Col lg={6} md={12}>
                  <PanelToday
                    auth={auth}
                    bsStyle="success"
                    title="Opgelost ouder, vandaag bijgewerkt"
                    filter={this.endedEarlier}
                    showEnded={true}
                    onSelect={this.showHistory}
                    data={data} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default DisruptionsToday
