import React from 'react'
import { Switch, Router, Route, Redirect } from 'react-router-dom'

import NavMenu from './nav/NavMenu'
import Home from './home/Home'
import Contact from './home/Contact'
import Disruptions from './disruptions/Disruptions'
import Open from './dashboard/DisruptionsOpen'
import Today from './dashboard/DisruptionsToday'

import Suspects from './suspects/Suspects'
import Responselog from './responses/Responselog'

import Clients from './clients'
import PullReport from './pullreport'

import Monitor from './notification/Monitor'

import Auth from './auth/Auth'
import Callback from './auth/Callback'
import history from './history'


import { createHasScope } from './auth/LoginUtil'


const auth = new Auth()

// eslint-disable-next-line
const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication()
  }
}

export function makeMainRoutes()  {
  const hasDashboard = createHasScope(auth, 'show:dashboard')
  const hasServices  = createHasScope(auth, 'show:services')
  const hasClient    = createHasScope(auth, 'show:client_admin')
  const hasSuspects  = createHasScope(auth, 'show:suspects')

  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route path="/monitor" render={props => <Monitor auth={auth} {...props} />} />
          <Route path="/" render={props => <NavMenu auth={auth} {...props} />} />
        </Switch>
        <Route exact path="/" render={() => (
          <Redirect to="/home" />
        )} />
        <Route path="/home" component={Home} />
        <Route path="/contact" component={Contact} />
        <Route path="/onderbrekingen" render={props => <Disruptions auth={auth} {...props} />} />
        <Route path="/dashboard/open" render={hasDashboard(props => <Open auth={auth} {...props} />)} />
        <Route path="/dashboard/vandaag" render={hasDashboard(props => <Today auth={auth} {...props} />)} />

        <Route path="/suspects" render={hasSuspects(props => <Suspects auth={auth} {...props} />)} />

        <Route path="/responselog" render={hasServices(props => <Responselog auth={auth} {...props} />)} />

        <Route path="/clients/report" render={hasServices(props => <PullReport auth={auth} {...props} />)} />
        <Route path="/clients/admin" render={hasClient(props => <Clients auth={auth} {...props} />)} />


        <Route path="/callback" render={props => {
          handleAuthentication(props)

          return <Callback auth={auth} {...props} />
        }} />
      </div>
    </Router>
  )
}
