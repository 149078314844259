import React from 'react'
import {formatDateTime} from '../util'
import {
  Grid,
  Table,
  Panel,
} from 'react-bootstrap'
import axios from 'axios'


const baseUrl = '/api/report/'

const z = n => (n < 10) ? '0' + n : n  // eslint-disable-line

function LastCallRow({client_id, name, baseUrl, path, lastCall, statusCode, statusText}) {
  return (
    <tr>
      <td>{formatDateTime(lastCall)}</td>
      <td>{name || client_id}</td>
      <td>{baseUrl}{path}</td>
      <td>{statusText}</td>
    </tr>
  )
}
function DailyRow({year, month, day, name, client_id, count}) {
  return (
    <tr key={`${year}${month}${day} ${client_id}`}>
      <td>{`${year}-${z(month)}-${z(day)}`}</td>
      <td>{name || client_id}</td>
      <td>{count}</td>
    </tr>
  )
}

function ReportTable({headers, caption, children}) {
  return (
    <Panel bsStyle={'info'}>
      <Panel.Heading >
        <Panel.Title componentClass="h3">{caption}</Panel.Title>
      </Panel.Heading>
      <Panel.Body>

        <Table striped bordered>
          <thead>
            <tr>
              { headers.map(h => <th key={h}>{h}</th>)}
            </tr>
          </thead>
          <tbody>
            { children }
          </tbody>
        </Table>

      </Panel.Body>
    </Panel>
  )
}

class PullReport extends React.Component {
  state = {
    lastReport: [],
    dailyReport: [],
  }

  componentDidMount() {
    this.fetchLastReport()
    this.fetchDailyReport()
  }

  fetchLastReport = () => {
    const { isAuthenticated, getAccessToken} = this.props.auth

    if (isAuthenticated()) {
      const headers = { 'Authorization': `Bearer ${getAccessToken()}`}

      const url = baseUrl + 'last'

      axios({url, method:'GET', headers})
        .then(response => this.setState({
          lastReport:response.data,
          error:false
        }))
        .catch(error => this.setState({report: [], error}))
    }
  }
  fetchDailyReport = () => {
    const { isAuthenticated, getAccessToken} = this.props.auth

    if (isAuthenticated()) {
      const headers = { 'Authorization': `Bearer ${getAccessToken()}`}

      const url = baseUrl + 'daily'

      axios({url, method:'GET', headers})
        .then(response => this.setState({
          dailyReport:response.data,
          error:false
        }))
        .catch(error => this.setState({report: [], error}))
    }
  }

  render() {
    const {lastReport, dailyReport} = this.state

    return (
      <Grid fluid>
        <ReportTable
          caption='Laatste aanroepen voor elke client per api context'
          headers={['Tijdstip', 'Naam', 'Context', 'Status']}>
          { lastReport.map(props => {
            const {client_id, baseUrl, path} = props

            return <LastCallRow key={`${client_id} ${baseUrl} ${path}`} {...props} />
          }) }
        </ReportTable>

        <ReportTable
          caption='Dagelijkse aanroepen voor elke client in de afgelopen 7 dagen'
          headers={['Datum', 'Naam', 'Aanroepen']}>
          { dailyReport.map(props => {
            const {year, month, day, client_id} = props

            return <DailyRow key={`${year}-${z(month)}-${z(day)} ${client_id}`} {...props} />
          }) }
        </ReportTable>
      </Grid>
    )
  }
}

export default PullReport
