import React from 'react'
import moment from 'moment'
import 'moment/locale/nl.js'

import PropTypes from 'prop-types'

import { TIME_FORMAT } from '../util'
import NotificationPanel from './NotificationPanel'

/**
 * Panel to display info about updates.
 * Uses NotificationPanel with some presets.
 *
 * Displays the number of updates in the last hour (in green) or
 * the time of the last update when updates equals 0 (in red)
 *
 * There is ALWAYS a neutral color when the current time is after working hours (19:00 - 7:00)
 *
 */
const UpdatePanel = props => {

  const BEFORE = moment('07:00', TIME_FORMAT)
  const AFTER  = moment('20:00', TIME_FORMAT)
  const SATURDAY = 6
  const SUNDAY = 0

  const isWeekend = moment().day() === SATURDAY || moment().day() === SUNDAY

  const num = parseInt(props.updates, 10) || 0
  const date = moment(props.datetime || '1970-01-01T00:00:00')

  const dt = date.isBefore(moment().startOf('day')) ? date.format('DD MMM') : date.format(TIME_FORMAT)

  let style = 'success'
  let kpi = num
  let message = 'Afgelopen uur'

  if (num <= 0) {
    style = 'danger'
    kpi = dt
    message = 'Laatste update'
  }

  if (!moment().isBetween(BEFORE, AFTER) || isWeekend) {
    style = undefined
  }

  return (
    <NotificationPanel
      bsStyle={style}
      kpi={kpi}
      description={`Updates ${props.dso}`}
      title={`Laatste update ${dt}`}
      message={message}
      glyph="list"
    />
  )
}

UpdatePanel.propTypes = {
  /** number of updates in the last hour */
  updates: PropTypes.string.isRequired,
  /** last known date and time the last update was received */
  datetime: PropTypes.string.isRequired,
  /** name of the dso, owner of the service the connection was made to */
  dso: PropTypes.string.isRequired,
}

export default UpdatePanel
