import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  DropdownButton,
  Grid,
  MenuItem,
  Table,
  Panel,
} from 'react-bootstrap'

import HistoryModal from '../dashboard/HistoryModal'
import ResponseRow from './ResponseRow'

import axios from 'axios'

export class Responselog extends Component {
  propTypes: {
    auth: PropTypes.object.required,

  }
  state = {
    error: false,
    responses: [],
    optionsHttpStatus: [],
    optionsConsumers: [],
    http_status: undefined,
    consumer: undefined,
  }

  componentDidMount() {
    this.query()
    this.getLabels()
  }

  onChangeDropdown = prop => value => this.setState({[prop] : value ? value : undefined }, this.query)

  getLabels = () => {
    const { isAuthenticated, getAccessToken} = this.props.auth

    if (!isAuthenticated())
      return

    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}

    axios('/api/responses/labels/consumer', {headers})
      .then(response => this.setState({optionsConsumers:response.data.sort()}))
      .catch(console.error) // eslint-disable-line

    axios('/api/responses/labels/http_status', {headers})
      .then(response => this.setState({optionsHttpStatus:response.data.sort()}))
      .catch(console.error) // eslint-disable-line
  }

  query = () => {
    const { isAuthenticated, getAccessToken} = this.props.auth

    if (!isAuthenticated())
      return

    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}
    const {http_status, consumer} = this.state
    const params = {http_status, consumer, expand: true}


    axios.get('/api/responses' , { params, headers })
      .then(response => this.setState({responses:response.data}))
      .catch(console.error) // eslint-disable-line
  }

  getHistory = id => {
    const { isAuthenticated, getAccessToken} = this.props.auth

    if (!isAuthenticated())
      return

    const headers = { 'Authorization': `Bearer ${getAccessToken()}`}

    axios('/api/dash/history/' + id, { headers })
      .then(response => this.setState({historyData:response.data, historyError: false}))
      .catch(historyError => this.setState({historyError}))
  }

  showHistory = d => e  => {
    e.preventDefault()
    this.setState({showModal: true, historyId: d._id, historyData: []})
    this.getHistory(d._id)
  }
  hideHistory = () => this.setState({showModal: false})

  render() {

    const {showModal, historyId, historyData} = this.state
    const {auth} = this.props

    return (

      <Grid fluid>
        <HistoryModal show={showModal} onHide={this.hideHistory} id={historyId} data={historyData} />
        <Panel bsStyle={'info'}>
          <Panel.Heading >
            <Panel.Title componentClass="h3">Push client response log</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <Table striped bordered>
              <thead>
                <tr>
                  <th colSpan={5}>Disruptions</th>
                  <th colSpan={4}>Responses</th>
                </tr>
                <tr>
                  <th>id</th>
                  <th colSpan={2}> source </th>
                  <th>date</th>
                  <th>
                    <DropdownButton id="status" bsSize="xsmall"
                      title={<strong>{this.state.http_status || 'Status'}</strong>}
                      onSelect={this.onChangeDropdown('http_status')} >
                      <MenuItem key='all' eventKey='all' active={this.state.http_status === 'all'}>Alle</MenuItem>
                      <MenuItem key=''    active={!this.state.http_status}>NOK</MenuItem>
                      {
                        this.state.optionsHttpStatus.map(v =>
                          <MenuItem key={v} eventKey={v} active={this.state.http_status === v}>{v}</MenuItem>
                        )
                      }
                    </DropdownButton>
                  </th>
                  <th>response body</th>
                  <th>
                    <DropdownButton id="consumer" bsSize="xsmall"
                      title={<strong>{this.state.consumer || 'Consumer'}</strong>}
                      onSelect={this.onChangeDropdown('consumer')} >
                      <MenuItem key='all' eventKey='' active={this.state.consumer === ''}>Alle</MenuItem>
                      {
                        this.state.optionsConsumers.map(v =>
                          <MenuItem key={v} eventKey={v} active={this.state.consumer === v}>{v}</MenuItem>
                        )
                      }
                    </DropdownButton>
                  </th>
                </tr>
              </thead>
              <tbody>

                {
                  this.state.responses.map(r => {
                    let key = r._id

                    if (r.disruption) {
                      key = `${key}_${r.disruption._id}`
                    }


                    return <ResponseRow key={key} {...r} onClick={auth.userHasScopes(['read:history']) && this.showHistory(r.disruption)}/>
                  })
                }
              </tbody>
            </Table>
          </Panel.Body>
        </Panel>
      </Grid>

    )
  }
}


export default Responselog
