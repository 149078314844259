import React from 'react'
import PropTypes from 'prop-types'
import {
  Navbar, Nav, NavItem,
  NavDropdown, MenuItem,
  Glyphicon,
} from 'react-bootstrap'
import './navmenu.css'

import { LinkContainer } from 'react-router-bootstrap'
import ProfileDropdown from './ProfileDropdown'

/**
 * Main navigation above all pages.
 *
 */
class NavMenu extends React.Component {
  static propTypes = {
    /**  contains authentication related data and functions */
    auth: PropTypes.shape({
      isAuthenticated: PropTypes.func.isRequired,
      userProfile: PropTypes.object,
      getProfile: PropTypes.func.isRequired,
      login: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
      userHasScopes: PropTypes.func.isRequired,
    }).isRequired
  }

  /**
   * Creates a new NavMenu
   *
  */
  constructor(props) {
    super(props)
    this.state = {
      profile: {}
    }
  }

  /**
   * Get profile from auth
   */
  getProfile = () =>{
    const { isAuthenticated, userProfile, getProfile } = this.props.auth

    if (this.state.profile && !this.state.profile.picture && isAuthenticated()) {
      if (!userProfile) {
        getProfile((err, profile) => {
          this.setState({ profile })
        })
      } else {
        this.setState({ profile: userProfile })
      }}
  }

  render() {
    this.getProfile()

    return (
      <div>
        <Navbar collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <a href="#brand">Energieonderbrekingen</a>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <LinkContainer to="/home">
                <NavItem>Home</NavItem>
              </LinkContainer>

              <LinkContainer to="/onderbrekingen">
                <NavItem>Overzicht Onderbrekingen</NavItem>
              </LinkContainer>

              <DashboardDropdown {...this.props.auth}/>

              <ServicesDropdown {...this.props.auth} />

            </Nav>

            <Nav pullRight>
              <LinkContainer to="/contact">
                <NavItem>Contact</NavItem>
              </LinkContainer>
              <Login {...this.props.auth} profile={this.state.profile} />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}


const DashboardDropdown = ({isAuthenticated, userHasScopes}) =>
  isAuthenticated() && userHasScopes(['show:dashboard']) &&
  <NavDropdown title="Dashboard" id="dashboard-dropdown">
    <LinkContainer to="/dashboard/open">
      <MenuItem>Open onderbrekingen</MenuItem>
    </LinkContainer>
    <LinkContainer to="/dashboard/vandaag">
      <MenuItem>Updates vandaag</MenuItem>
    </LinkContainer>
    { userHasScopes(['show:suspects']) &&
    <LinkContainer to="/suspects">
      <NavItem>Overzicht veronderstelde onderbrekingen</NavItem>
    </LinkContainer>
    }
  </NavDropdown>

const ServicesDropdown = ({isAuthenticated, userHasScopes}) =>
  isAuthenticated() && userHasScopes(['show:services']) &&
  <NavDropdown title="Services" id="services-dropdown">
    { userHasScopes(['show:client_admin']) &&
      <LinkContainer to="/clients/admin">
        <NavItem>Pull API Clients</NavItem>
      </LinkContainer>
    }
    <LinkContainer to="/clients/report">
      <NavItem>Pull API requests</NavItem>
    </LinkContainer>
    <LinkContainer to="/responselog">
      <NavItem>Push API responses</NavItem>
    </LinkContainer>
  </NavDropdown>



const Login = ({isAuthenticated, login, logout, profile}) => isAuthenticated()
  ? <ProfileDropdown profile={profile} logout={logout} />
  : <NavItem onClick={login}><Glyphicon glyph="lock" /> Inloggen </NavItem>


export default NavMenu
