import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {Glyphicon} from 'react-bootstrap'

import { formatDateTime } from '../util'

export function ResponseRow(props) {

  return (
    <tr>
      { props.disruption ?
        <Fragment>
          <td>{ props.disruption._id }</td>
          <td style={{minWidth: '7em'}}>
            <Glyphicon glyph={ props.disruption.planned ? 'calendar' : 'alert' } style={{'color': '#b1b1b1'}}/> { props.disruption.source.organisation }</td>
          <td>

            { props.onClick
              ? <a href={`/history/${props.disruption._id}`} onClick={props.onClick} >{props.disruption.source.id }</a>
              : props.disruption.source.id
            }
          </td>
        </Fragment>
        :
        <Fragment>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </Fragment>
      }
      <td>{ formatDateTime(props.timestamp)}</td>
      <td>{ props.http_status }</td>
      <td><code type="json">{ JSON.stringify(props.response) }</code></td>
      <td>{ props.consumer }</td>
    </tr>
  )
}

ResponseRow.propTypes = {
  _id: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  http_status: PropTypes.any.isRequired,
  response: PropTypes.any.isRequired,
  consumer: PropTypes.string.isRequired,
}

export default ResponseRow
