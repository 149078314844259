import './disruptions.css'

import {
  Alert,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Panel,
  Row,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap'
import React  from 'react'
import axios from 'axios'
import moment from 'moment'

import { debounce } from '../util'
import {
  optionsOpgelost, optionsNetwerk, optionsNetbeheerder, values
} from '../dropdowns/options'
import DisruptionsPanelGroup from './DisruptionsPanelGroup'
import DropDown from './Dropdown'

const LIMIT_MIN = 5          // disruptions
const LIMIT_DEFAULT = 25     // disruptions
const LIMIT_MAX = 200        // disruptions

const ONE_THOUSAND_MILLIS = 1000
const MAX_SECONDS = 60

class Disruptions extends React.Component {
  defaults = {
    dso: values(optionsNetbeheerder),
    resolved: values(optionsOpgelost),
    network: values(optionsNetwerk),
  }

  state = {
    ...this.defaults,
    resolved: [values(optionsOpgelost)[1]],
    limit: LIMIT_DEFAULT,
    data: [],
    error: false,
    fetched_formatted: '',
  }

  componentDidMount() {
    this.query()
    this.interval = setInterval(this.updateFetchedFormat, ONE_THOUSAND_MILLIS)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    clearInterval(this.timer)
  }

  login = () => this.props.auth.login()
  logout = () => this.props.auth.logout()

  query = debounce(() => {
    const { limit, dso, network } = this.state

    const resolved = this.state.resolved.length === 1 ? this.state.resolved[0] : undefined

    const params = { limit, dso, resolved, network }

    axios.get('/api/disruptions', {params})
      .then(response => this.setState({data:response.data, error: false, fetched: moment()}))
      .catch(error => this.setState({error}))
  })

  toggleState = prop => () => this.setState({[prop]: !this.state[prop]})
  toggleVerversen = () => this.setState(state => {
    if (state.verversen) {
      clearInterval(this.timer)

      return {verversen: false}
    }
    this.query()
    this.timer = setInterval(this.query, 90500) // eslint-disable-line

    return {verversen: true}
  })

  updateFetchedFormat = () => {
    const {fetched} = this.state

    if (fetched) {
      const diff = Math.ceil(moment().diff(fetched) / ONE_THOUSAND_MILLIS)
      const wanneer = diff < MAX_SECONDS
        ? `${diff} seconden geleden`
        : this.state.fetched.fromNow()

      this.setState({fetched_formatted: `De data is ${wanneer} opgehaald.`})
    }
  }

    onChangeDropdown = prop => selectedOption => {
      let value = this.defaults[prop]

      if (selectedOption) {
        if (Array.isArray(selectedOption)) {
          if (selectedOption.length > 0) {
            value = values(selectedOption)
          }
        } else {
          value = selectedOption.value
        }
      }

      this.setState({[prop] : value }, this.query)
    }

  onChangeLimit = event => {
    const value = event.target.value
    const limit = Math.max(LIMIT_MIN, Math.min(value, LIMIT_MAX))

    this.setState({ limit }, this.query)
  }

  render() {
    const {dso, network, resolved, error, limit, data} = this.state


    let message

    if (error) {
      message = error.message
      if (error.response && error.response.data && error.response.data.message)
        message = error.response.data.message
    }

    return (
      <Grid fluid>
        <div>
          <Panel bsStyle="primary">
            <Panel.Heading>
              <Panel.Title componentClass="h3">Onderbrekingen in elektriciteit- en gasnetwerken</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              <Row>
                <Col md={2} sm={6}>
                  <DropDown id="dso" label="Netbeheerder" multi={true}
                    value={dso} options={optionsNetbeheerder}
                    onChange={this.onChangeDropdown('dso')} />
                </Col>
                <Col md={2} sm={6}>
                  <DropDown id="resolved" label="Opgelost" multi={true}
                    value={resolved} options={optionsOpgelost}
                    onChange={this.onChangeDropdown('resolved')} />
                </Col>
                <Col md={3} sm={6}>
                  <DropDown id="network" label="Netwerk" multi={true}
                    value={network} options={optionsNetwerk}
                    onChange={this.onChangeDropdown('network')} />
                </Col>
                <Col md={2} sm={6}>
                  <FormGroup controlId="limit">
                    <ControlLabel>Limiet</ControlLabel>
                    <FormControl value={limit} type="number" min={LIMIT_MIN} max={LIMIT_MAX} step="5" onChange={this.onChangeLimit} />
                  </FormGroup>
                </Col>
                <Col md={3} sm={6}>
                  <FormGroup controlId="limit">
                    <ControlLabel>{this.state.fetched_formatted}</ControlLabel><br/>
                    <ToggleButtonGroup type="checkbox" value={this.state.verversen ? [1] : []} onChange={this.toggleVerversen}>
                      <ToggleButton value={1}>Automatisch verversen</ToggleButton>
                    </ToggleButtonGroup>
                  </FormGroup>
                </Col>
              </Row>
              { message
                ? <Alert bsStyle="danger">{message}</Alert>
                : <DisruptionsPanelGroup data={data} /> }
            </Panel.Body>
          </Panel>
        </div>
      </Grid>
    )
  }
}

export default Disruptions
